import MainContent from "./MainContent.jsx";

import logoBlack from "../../../assets/images/logo-black.png";
import logoWhite from "../../../assets/images/logo-white.png";
import { Link, Navigate, useLocation } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import { useEffect, useRef } from "react";
const MainLayout = () => {
  const handleEarlyAccess = () => {
    // localStorage.clear();
    Navigate("/get-early-access");
  };

  const ref = useRef(null);
  const location = useLocation();
  useEffect(() => {
    ref.current.continuousStart()
    setTimeout(() => {
      ref.current.complete()
    }, 100)
  }, [location])
  return (
    <>
      <LoadingBar color="#305ff0" ref={ref} shadow={true} height={3} />
      <div className="sb-nav-fixed overall-body">
        {/* <TheHeader /> */}
        <div
          id="layoutSidenav"
        // className={collapsed ? "sidebar-minimized" : "un-minimized"}
        >
          <div id="layoutSidenav_content" className={"create-vch-header"}>
            <nav
              className="navbar navbar-expand-sm navbar-dark kitaab-navbar"
              id="kitaab-navbar"
            >
              <div className="container-fluid mobile-container-fluid">
                <a className="navbar-brand" href="/">
                  <img src={logoBlack} className="img-fluid" width="138" />
                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#mynavbar"
                >
                  <span className="navbar-toggler-icon">
                    <i className="fi fi-br-bars-staggered text-color-3 l-h-1 v-align-middle" />
                  </span>
                </button>
                <div className="collapse navbar-collapse" id="mynavbar">
                  <ul className="navbar-nav me-auto me-32 ms-32">
                    <li className="nav-item">
                      <a className="nav-link" href="/">
                        Features
                      </a>
                    </li>
                    <li className="nav-item">
                      <Link to="/pricing" className="nav-link">
                        Pricing
                      </Link>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="home">
                        Partner with us
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="home">
                        Try Demo Mode
                      </a>
                    </li>
                  </ul>
                  <form className="d-flex mobile-top-12 mobile-header-btns">
                    <button
                      className="btn btn-outline-primary btn-padding me-16 btn-padding font-size-16 b-r-8"
                      type="button"
                    >
                      Book a Demo
                    </button>
                    <Link to="/get-early-access"

                      className="btn btn-primary btn-padding btn-padding font-size-16 b-r-8"
                    >
                      Get Early Access
                    </Link>
                  </form>
                </div>
              </div>
            </nav>

            <main>
              <div className="">
                <MainContent />
              </div>
            </main>

            <footer className="bg-dark text-light">
              <div className="container px-5 mobile-p-56 mobile-p-s-e-0">
                <div className="row pt-4 pb-3 mobile-top-0-imp mobile-bottom-0-imp">
                  <div className="col-6 mobile-bottom-8 mobile-text-start ">
                    <img
                      src={logoWhite}
                      className="img-fluid"
                      width="140"
                    ></img>
                  </div>
                  <div className="col-6 text-end mobile-font-icons">
                    <button className="btn btn-link text-white font-size-16 text-dec-none">
                      <i className="fi fi-brands-facebook"></i>
                    </button>
                    <button className="btn btn-link text-white font-size-16 text-dec-none">
                      <i className="fi fi-brands-twitter-alt"></i>
                    </button>
                    <button className="btn btn-link text-white font-size-16 text-dec-none">
                      <i className="fi fi-brands-instagram"></i>
                    </button>
                    <button className="btn btn-link text-white font-size-16 text-dec-none">
                      <i className="fi fi-brands-linkedin"></i>
                    </button>
                    <button className="btn btn-link text-white font-size-16 text-dec-none">
                      <i className="fi fi-brands-youtube"></i>
                    </button>
                  </div>
                </div>
                <hr className="hr-d-color mt-2 mb-3"></hr>
                <div className="row">
                  <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12">
                    <div className="row">
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 mobile-bottom-16">
                        <div className="">
                          <p className="font-size-18 f-600 mb-0">Product</p>
                        </div>
                        <div id="product" className="footer-list mt-1 collapse show">
                          <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                              <a href="#">Features</a>
                            </li>
                            <li className="list-group-item">
                              <a href="#">Pricing</a>
                            </li>
                            <li className="list-group-item">
                              <a href="#">Case Studies</a>
                            </li>
                            <li className="list-group-item">
                              <a href="#">Reviews</a>
                            </li>
                            <li className="list-group-item">
                              <a href="#">Updates</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 mobile-bottom-16">
                        <p className="font-size-18 f-600 mb-0">Company</p>
                        <div className="footer-list mt-1">
                          <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                              <a href="#">About</a>
                            </li>
                            <li className="list-group-item">
                              <a href="#">Contact Us</a>
                            </li>
                            <li className="list-group-item">
                              <a href="#">Careers</a>
                            </li>
                            <li className="list-group-item">
                              <a href="#">Culture</a>
                            </li>
                            <li className="list-group-item">
                              <a href="#">Blog</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 mobile-bottom-16">
                        <p className="font-size-18 f-600 mb-0">Support</p>
                        <div className="footer-list  mt-1">
                          <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                              <a href="#">Getting Started</a>
                            </li>
                            <li className="list-group-item">
                              <a href="#">Help Center</a>
                            </li>
                            <li className="list-group-item">
                              <a href="#">Server Status</a>
                            </li>
                            <li className="list-group-item">
                              <a href="#">Report a Bug</a>
                            </li>
                            <li className="list-group-item">
                              <a href="#">Chat Support</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 mobile-bottom-16">
                        <p className="font-size-18 f-600 mb-0">Downloads</p>
                        <div className="footer-list  mt-1">
                          <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                              <a href="#">iOS</a>
                            </li>
                            <li className="list-group-item">
                              <a href="#">Android</a>
                            </li>
                            <li className="list-group-item">
                              <a href="#">Mac</a>
                            </li>
                            <li className="list-group-item">
                              <a href="#">Windows</a>
                            </li>
                            <li className="list-group-item">
                              <a href="#">Chrome</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12">
                    <div className="row">
                      <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <p className="font-size-18 f-600 mb-0">
                          Join Kitaabh Now
                        </p>
                        <p className="font-size-16 f-500 text-white mb-0  mt-1">
                          Get early access and experience Kitaabh's powerful
                          accounting features
                        </p>
                        <div className="mt-16">
                          <input
                            type="text"
                            className="form-control footer-inp"
                            placeholder="Enter your Email"
                          ></input>
                        </div>
                        {/* <div className="mt-16">
                          <button className="btn btn-primary b-r-8 font-size-14 btn-padding">
                            Join Waitlist
                          </button>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="mb-2" />
                <div className="text-center py-1">
                  <p className="font-size-12 mb-1">2023 &copy; Kitaabh. All Rights Reserved. </p>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainLayout;
