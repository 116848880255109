import React from "react";




const Dashboard = React.lazy(() =>
  import("./components/layout/dashboard/Dashboard")
);
const HomePage = React.lazy(() => import("./components/layout/home/Home"));

const PricingPage = React.lazy(() =>
  import("./components/layout/pricing/Pricing")
);

const GetEarlyAccess = React.lazy(() =>
  import("./components/layout/early-access/GetEarlyAccess")
);
const ThankYou = React.lazy(() =>
  import("./components/layout/thank-you/ThankYou")
);

const routes = [
  { path: "dashboard", component: Dashboard },
  { path: "/", component: HomePage },
  { path: "pricing", component: PricingPage },
  { path: "get-early-access", component: GetEarlyAccess },
  { path: "thank-you", component: ThankYou },
];

export default routes;
